.custom-switcher {
  display: flex;
  overflow: hidden;
  margin-bottom: 20px;

  .switcher-item {
    color: $primary-color;
    background: var(--lihgt);
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 0 16px;
    min-width: 50%;
    text-decoration: none;

    @include md {
      min-width: 160px;
    }

    &.active {
      color: var(--lihgt);
      background: $primary-color;
      border: none;
    }

    &:first-child {
      border-radius: 3px 0 0 3px;
    }

    &:last-child {
      border-radius: 0 3px 3px 0;
    }

    &.disabled {
      pointer-events: none;
      user-select: none;
      opacity: 0.6;
    }
  }
}
