button {
  font-family: inherit;
  cursor: pointer;

  &:focus {
    outline: none;
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  font-size: 14px;
  padding: 10px 20px;
  font-weight: 500;
  text-decoration: none;
  text-align: center;
  box-shadow: none;
  border-radius: 25px;
  border: none;
  transition: all 0.3s;
  min-height: 40px;

  @include sm {
    padding: 11px 25px;
  }

  &:hover {
    text-decoration: none;
    transition: all 0.3s;
  }

  &.btn-round {
    padding: 10px;
    border-radius: 50%;
    border: 1px solid var(--gray);
    cursor: pointer;
    max-width: 48px;

    &:hover {
      background-color: var(--primary);
      border-color: var(--primary);
      transition: .3s;
    }
  }

  &:disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .mat-icon {
    margin-right: 7px;
    font-size: 17px;
    width: 16px;
    height: 16px;
  }
}

.btn-primary {
  background: var(--primary);
  border: 1px solid var(--primary);
  color: var(--font-text-secondary);

  &:hover,
  &:active {
    background: var(--light);
    color: var(--font-text-base);
  }
}

.btn-secondary {
  background: var(--light);
  border: 1px solid var(--primary);
  color: var(--font-text-base);

  &:hover,
  &:active {
    background: var(--primary);
    color: var(--font-text-secondary);
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.menu-button .mat-button-focus-overlay {
  background: transparent !important;
  opacity: 0 !important;
}

/* TODO(mdc-migration): The following rule targets internal classes of button that may no longer apply for the MDC version. */
.menu-button.sdk-keyboard-focused .mat-button-focus-overlay {
  opacity: 0 !important;
}
