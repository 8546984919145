.files-menu-backdrop {
  + .cdk-overlay-connected-position-bounding-box {
    .mat-mdc-menu-panel {
      border-radius: 0;
    }

    .mat-mdc-menu-content {
      padding: 0;
    }
  }
}

.notifications-menu-backdrop,
.send-message-menu-backdrop {
  + .cdk-overlay-connected-position-bounding-box {
    .mat-mdc-menu-panel {
      max-width: none !important;
      border-radius: 4px;
      overflow: hidden;
      box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3), 0px 2px 6px 2px rgba(0, 0, 0, 0.15);
    }

    .mat-mdc-menu-content {
      padding: 0;
    }
  }
}

.mat-mdc-fab:hover,
.mat-mdc-fab:focus,
.mat-mdc-mini-fab:hover,
.mat-mdc-mini-fab:focus {
  box-shadow: none !important;
}

.mat-mdc-fab, .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: transparent !important;
}
