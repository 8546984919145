@import "breakpoints";

html,
body {
  margin: 0;
  background-color: var(--light);
  min-height: 100vh;
  font-size: 14px;
}

body {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  color: var(--font-text-base);
  position: relative;
  line-height: 1.2;

  * {
    box-sizing: border-box;
  }
}

.global_wrap {
  position: relative;
  z-index: 1;

  &_bg {
    position: fixed;
    inset: 0;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-attachment: fixed;
    z-index: -1;
  }
}

.global_img_wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
}

img {
  max-width: 100%;
  height: auto;
}

.page-container {
  padding: 20px 0;
  max-width: 1500px;
  margin: 0 auto;

  @include sm {
    padding: 20px 15px;
  }
}

.additional-actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 20px;

  .btn + .btn {
    margin-left: 20px;
  }
}

hr {
  width: 100%;
  border: none;
  border-top: 1px solid var(--lihgt);
}

.authorized .grecaptcha-badge {
  right: -300px !important;
}

.title {
  font-weight: 500;
}

.card {
  background-color: var(--light);
  border: 1px solid var(--gray);
  border-radius: 12px;
  padding: 15px;
}

.authorized .grecaptcha-badge {
  right: -300px !important;
}

.header_nav {
  & a {
    padding: 10px 20px;
    margin: 0 5px;
    border-radius: 25px;
    background-color: transparent;
    transition: .3s;
    text-decoration: none;
    font-weight: 500;
    color: var(--font-text-primary);

    &:hover,
    &.active {
      background-color: var(--primary);
      transition: .3s;
      text-decoration: none;
      color: var(--font-text-secondary);
    }
  }
}

.authorized .grecaptcha-badge {
  right: -300px !important;
}

.authorized .grecaptcha-badge {
  right: -300px !important;
}

.payment_info {
  margin-top: 10px;
  color: #90A4AE;
}

.apexcharts-tooltip {
  background: rgba(0, 0, 0, .7) !important;
  color: #fff;
}

.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background: transparent !important;
  border-bottom: none !important;
}

.apexcharts-text.apexcharts-xaxis-label {
  font-weight: 400 !important;
}

.filter_container .mat-drawer-backdrop {
  position: fixed;
}

app-validator-message {
  position: absolute;
  inset: 0;
}

.error {
  font-size: 12px;
}

.custom_error {
  font-size: 12px;
  position: absolute;
  width: 100%;
  height: fit-content;
  left: 16px;
  text-align: left;
  color: var(--mdc-theme-error, #f44336);
  letter-spacing: var(--mdc-typography-caption-letter-spacing, .0333333333em);
}

