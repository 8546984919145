@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?g7ezs0');
  src:  url('fonts/icomoon.eot?g7ezs0#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?g7ezs0') format('truetype'),
    url('fonts/icomoon.woff?g7ezs0') format('woff'),
    url('fonts/icomoon.svg?g7ezs0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-clinic:before {
  content: "\e900";
}
.icon-notification-success-payout:before {
  content: "\e901";
}
.icon-start-call:before {
  content: "\e902";
}
.icon-voice-off:before {
  content: "\e903";
}
.icon-video-on:before {
  content: "\e904";
}
.icon-voice-on:before {
  content: "\e905";
}
.icon-video-off:before {
  content: "\e906";
}
.icon-end-call:before {
  content: "\e907";
}
.icon-education:before {
  content: "\e908";
}
.icon-photo:before {
  content: "\e909";
}
.icon-home:before {
  content: "\e90a";
}
.icon-logout:before {
  content: "\e90b";
}
.icon-help:before {
  content: "\e90c";
}
.icon-profile:before {
  content: "\e90d";
}
.icon-files:before {
  content: "\e90e";
}
.icon-schedule:before {
  content: "\e90f";
}
.icon-patients:before {
  content: "\e910";
}
.icon-consult:before {
  content: "\e911";
}
.icon-add-file:before {
  content: "\e912";
}
.icon-currency:before {
  content: "\e913";
}
.icon-waiting:before {
  content: "\e914";
}
.icon-search:before {
  content: "\e915";
}
.icon-analyzes:before {
  content: "\e916";
}
.icon-message:before {
  content: "\e917";
}
.icon-notification-new-dialogue:before {
  content: "\e918";
}
.icon-notification-analyzes-status:before {
  content: "\e919";
}
.icon-notification-recommendation-status:before {
  content: "\e91a";
}
.icon-notification-connect-chat:before {
  content: "\e91b";
}
.icon-notification-connect-wr:before {
  content: "\e91c";
}
.icon-notification-left-chat:before {
  content: "\e91d";
}
.icon-notification-left-wr:before {
  content: "\e91e";
}
.icon-wallet:before {
  content: "\e91f";
}
