fieldset {
  border: none;
  padding: 0;
}

textarea {
  font-family: inherit;
}

.mat-mdc-form-field {
  width: 100%;
}

.phone {
  padding-bottom: 1.34375em;
  position: relative;

  & .iti {
    width: 100% !important;

    #phone {
      width: 100%;
      padding: 11px 10px 11px 90px !important;
      border-radius: 10px;
      border: 1px solid rgba(0, 0, 0, 0.12);

      &:focus-visible {
        outline: #66B1F4;
      }
    }
  }

  &_profile {
    margin-top: 4px;

    & .iti {
      #phone {
        width: 100%;
        padding: 13px 10px 13px 90px !important;
        border-radius: 10px;
        border: 1px solid rgba(0, 0, 0, 0.12);

        &:focus-visible {
          outline: #66B1F4 solid 2px;
          border: none;
        }
      }
    }

    & .ng-invalid {
      #phone {
        &:focus-visible {
          outline: var(--warn-color) solid 2px;
          border: none;
        }
      }
    }
  }

  &_number {
    /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
    & .mat-form-field-infix {
      width: inherit !important;
    }
  }

  .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
    background-color: var(--light);
  }

  &_placeholder {
    position: absolute;
    font-size: 10px;
    padding: 1px 6px;
    background-color: #fff;
    top: -5px;
    left: 6px;
    color: rgba(0, 0, 0, 0.6);
    z-index: 1;
  }

  .search-container {
    padding: 15px;
  }

  #country-search-box {
    &:focus-visible {
      outline: none;
    }
  }

  .iti__country-list {
    box-shadow: none;
  }

  .iti__selected-flag {
    width: 80px !important;
  }
}

.phone_payment {
  .country-dropdown {
    max-width: 365px;
    inset: -15% auto auto 0px !important;
  }
}

.communications_list {
  padding-top: 0 !important;

  .mat-mdc-list-item {
    height: 28px !important;

    &-content {
      padding: 5px 0 !important;
    }
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
.auth .mat-form-field-appearance-outline .mat-form-field-outline-start {
  max-width: 4px !important;
}

.search {
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  & .mat-form-field-flex {
    align-items: center;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of form-field that may no longer apply for the MDC version. */
  & .mat-form-field-prefix {
    width: 16px;
    height: 16px;
  }
}

.measurement_form {
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-form-field-appearance-outline .mat-select-arrow-wrapper {
    transform: translateY(0) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
  .mat-select-arrow {
    color:  #90A4AE !important;
  }
}

/* TODO(mdc-migration): The following rule targets internal classes of option that may no longer apply for the MDC version. */
.mat-primary .mat-mdc-option.mat-selected:not(.mat-option-disabled) {
  color: var(--font-text-base);
}
