a {
  text-decoration: none;
  color: var(--font-text-base);

  &:hover {
    color: var(--font-text-base);
    text-decoration: underline;
  }
}

.section-title,
.section-subtitle {
  text-align: center;
  font-weight: $bold-font-weight;
  margin-top: 0;
  margin-bottom: 20px;
}

.section-title {
  font-size: 1.857rem;
}

.section-subtitle {
  font-size: 1.286rem;
}
