.invite-form {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: $primary-background;
  box-shadow: $primary-box-shadow;
  border-radius: $border-radius;
  margin-top: 50px;
  padding: 10px;

  @include xl {
    flex-direction: row;
    padding: 10px 50px 10px 20px;
  }

  .title {
    margin-bottom: 10px;
    font-weight: $medium-font-weight;
    font-size: 1.286rem;
    text-align: center;

    @include xl {
      text-align: left;
    }
  }

  .invite-field {
    flex-grow: 1;

    @include xl {
      margin-right: 20px;
    }
  }

  mat-form-field {
    width: 100%;
    max-width: 570px;
    margin-bottom: 20px;

    @include xl {
      margin-bottom: 0;
    }
  }
}
