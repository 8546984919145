.mat-datepicker-toggle {
  .mat-button-wrapper {
    color: var(--lihgt);
  }
}

.mat-date-range-input {
  .mat-date-range-input-separator {
    color: var(--lihgt);
  }
}

.mat-calendar {
  table tbody tr {
    &:hover {
      background-color: transparent;
    }
  }

  &:not(.base_calendar) {
    min-height: 370px;
  }
}

.timepicker {
  &-overlay,
  &-backdrop-overlay {
    z-index: 9999 !important;
  }
}

.timepicker {
  width: 100% !important;

  &__actions {
    justify-content: center !important;

    .btn {
      margin: 5px;
    }
  }
}

ngx-material-timepicker-period {
  display: none !important;
}

.calendar-wrapper {
  .mat-calendar-body {
    tr[aria-hidden='true'] {
      display: none;
    }

    td[aria-hidden='true'] {
      visibility: hidden;
    }
  }

  .mat-calendar-content {
    padding: 0;
  }

  .mat-calendar-body-cell-content {
    color: var(--gray);
    font-weight: 400;
    font-size: 14px;
    max-width: 36px;
    max-height: 36px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    &.mat-calendar-body-selected {
      font-weight: 800;
    }
  }

  .mat-calendar-body-disabled > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
    color: var(--gray);
  }

  .mat-calendar-body-cell {
    cursor: default;
    pointer-events: none;
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    color: var(--gray);

    &:not(.mat-calendar-body-disabled):hover
    > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected) {
      background-color: transparent;
    }

    &.events-date {
      cursor: pointer;
      pointer-events: auto;

      .mat-calendar-body-cell-content {
        color: var(--font-text-base);
      }
    }
  }

  .mat-calendar-body-cell-content {
    color: var(--gray);

    &:hover {
      background-color: var(--primary-light) !important;
      opacity: 0.8;
    }
  }

  .cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
  .cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
    background-color: transparent !important;
  }

  &.medication {
    .mat-calendar-body-cell {
      &.events-date {
        .mat-calendar-body-cell-content {
          color: var(--font-text-base);
          border-color: var(--primary);
        }
      }
    }
  }
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border: none !important;

  &:focus {
    background-color: transparent !important;
  }
}

.mat-calendar-body-selected {
  background-color: var(--primary-light);
  font-weight: 500;
  box-shadow: none !important;
}

.mat-calendar-body-label {
  color: var(--font-text-base);
  padding-top: inherit !important;
  padding-bottom: inherit !important;
}

.mat-calendar-table-header {
  color: var(--font-title);
  background: transparent;
  text-transform: capitalize;

  th {
    padding: 15px 0 !important;
    font-weight: 400;
    font-size: 16px;
  }

  .mat-calendar-table-header-divider {
    display: none;
  }
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--primary);
}

.mat-calendar-body-cell-container {
  padding: 20px !important;
}

