
/* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
mat-paginator.mat-mdc-paginator {
  background-color: transparent;
  color: var(--font-text-base);
  padding: 0;
  font-size: 0.857rem;

  .mat-mdc-paginator-page-size {
    margin-right: 0;

    .ng-star-inserted {
      color: $header-color;
    }
    /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
    .mat-select-arrow {
      color: $text-color !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of paginator that may no longer apply for the MDC version. */
  .mat-paginator-page-size-select {
    width: 40px;
  }

  .mat-mdc-paginator-navigation-previous,
  .mat-mdc-paginator-navigation-next {
    line-height: 1;

    &:disabled {
      color: lighten($text-color, 50%);
    }
  }

  .mat-mdc-paginator-range-label {
    margin: 0 6px;
  }

  &:not(.small-paginator) {
    @include md {
      font-size: 1rem;
    }

    .mat-mdc-paginator-container {
      position: relative;
      @include md {
        flex-wrap: wrap;
      }
    }

    .mat-mdc-paginator-page-size {
      @include md {
        margin-right: 4px;
      }
    }

    .mat-mdc-paginator-navigation-previous,
    .mat-mdc-paginator-navigation-next {
      @include md {
        width: 40px;
        height: 40px;
      }
    }

    .mat-mdc-paginator-range-label {
      position: absolute;
      left: 0;
    }
  }

  .mat-mdc-paginator-page-size-label {
    display: none;

    @include sm {
      display: block;
    }
  }
}
