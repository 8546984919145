@import "breakpoints";

.mat-mdc-dialog-container .mdc-dialog__surface {
  border-radius: 12px !important;
}

.primary-modal {
  width: 100%;
  border-radius: 0;
  position: relative;
  overflow-y: auto;
  height: min-content;
  max-height: 80vh;
  max-width: 90vw !important;

  @include sm {
    max-height: 95vh;
    max-width: 450px !important;
  }

  &.modal-sm {
    @include md {
      max-width: 600px !important;
    }
  }

  &.modal-md {
    @include md {
      max-width: 760px !important;
    }
  }

  &.modal-xl {
    @include md {
      max-width: 990px !important;
    }
  }

  .mdc-dialog__container {
    border-radius: 6px;
    padding: 0;
    background-color: var(--lihgt);
  }

  .dialog-content {
    padding: 10px 0;
    color: var(--font-text-base);
    flex-wrap: nowrap;

    @include sm {
      padding: 10px 18px;
    }

    p {
      margin: 0;
    }
  }

  .mat-mdc-dialog-actions {
    justify-content: center;
    flex-wrap: nowrap;
    margin-bottom: 0;

    button {
      margin: 8px;
    }
  }
}

.modal-upload-file {
  .mat-mdc-dialog-actions {
    flex-wrap: wrap;
  }
}

.cdk-global-overlay-wrapper {
  height: 100vh !important;
}

.cdk-overlay-backdrop {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.cdk-overlay-container {
  position: fixed;
}


.modal-wrap {
  padding: 15px;
  overflow-y: auto;
  background-color: var(--light);
  border-radius: 12px;

  &_info {
    padding: 0 15px 15px;
  }
}

.modal_content {
  background-color: var(--light);
  border-radius: 12px;
}

.modal_image {
  padding-top: 15px;
  display: flex;
  justify-content: center;

  img {
    width: auto;
  }

  @include md {
    padding-top: 25px;
  }
}

.file-modal {
  .mat-mdc-dialog-container {
    max-height: 90vh;
    overflow: hidden;
  }
}


