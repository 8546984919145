.badge{
  background-color: var(--secondary);
  color: var(--font-text-base);
  height: 16px;
  width: 20px;
  border-radius: 16px;
  font-weight: 400;
  font-size: 12px;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -10px;
  top: -10px;
}

.mat-mdc-tooltip {
  font-size: 12px;
  color: var(--lihgt);
  background-color: #37474F;
  border-radius: 4px;
}
