.mat-form-field-appearance-outline {
  .mat-mdc-form-field-infix {
    padding: 0.7em 0 0.7em !important;
    display: flex;
    align-items: center;
    min-height: 42px !important;
  }

  .mat-mdc-form-field-infix {
    margin-top: 0;
  }

  &.mat-form-field-invalid.ng-touched {
    .mat-mdc-form-field-flex .mat-mdc-floating-label:not(.mdc-floating-label--float-above) {
      top: 20px !important;
    }
  }
}

.mat-form-field-appearance-fill {
  .mdc-text-field--filled {
    background: transparent !important;
  }

  .mat-mdc-text-field-wrapper {
    border-radius: 0 !important;
  }
}

.mdc-tab__text-label {
  letter-spacing: 0.3px;
}

ngx-material-timepicker-toggle {
  path {
    fill: #90A4AE;
  }
}

:root {
  --mdc-outlined-text-field-label-text-size: 14px;
  --mat-form-field-container-text-size: 14px;
  --mat-form-field-container-height: 42px;
  --mat-tab-header-label-text-tracking: -0.5px;
  --mdc-tab-indicator-active-indicator-height: 4px;
  --mat-tab-header-inactive-label-text-color: #90a4ae;
  --mat-tab-header-active-label-text-color: var(--font-base);
  --mat-datepicker-calendar-date-selected-state-background-color: var(--primary-light);
}
