@import "breakpoints";

.mat-mdc-tab-group.mat-primary .mat-ink-bar,
.mat-mdc-tab-nav-bar.mat-primary .mat-ink-bar {
  background-color: var(--secondary);
  height: 4px;
}

.mat-mdc-tab-link {
  font-weight: 400;
  color: #90A4AE;
  opacity: 1 !important;
  position: relative;
  padding: 0 15px !important;
  min-width: 50px !important;
  text-decoration: none;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 4px;
    width: calc(100% - 2px);
    background-color: #ECEFF1;

    @include md {
      width: 100%;
    }
  }

  &:hover {
    text-decoration: none;
  }

  @include sm {
    padding: 0 15px !important;
    min-width: 70px !important;
  }

  @include md {
    padding: 0 25px !important;
    min-width: 160px !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  &.mat-tab-label-active {
    color: var(--font-title);
  }
}

.mat-mdc-tab-header {
  border-bottom: 0;
}

.profile {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-label-active {
    color: var(--font-text-base);
    opacity: 1;
  }
}

.consultations_tabs {
  /* TODO(mdc-migration): The following rule targets internal classes of tabs that may no longer apply for the MDC version. */
  .mat-tab-link-container {
    overflow-x: auto;
  }
}
