$accent-color: #3adcff;
$accent-muted-color: #0085ff;
$warn-color: #f44336;
$muted-color: rgba(255, 255, 255, 0.64);
$muted-disabled-color: rgba(255, 255, 255, 0.42);
$grey-color: #ffffff4d;

// variables for doctors4UA
$header-color: #040A45;
$sidebar-color: #DAF6EC;
$text-color: #1B4761;
$primary-color: #09CF9E;

$regular-font-weight: 400;
$medium-font-weight: 500;
$bold-font-weight: 500;

$primary-background: #323c79;
$dark-background: #141d3c;
$accent-background: #4452a5;
$notification-background: rgba(0, 133, 255, 0.5);

$primary-box-shadow: 0 4px 40px #172039;
$border-radius: 6px;
