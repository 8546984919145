mat-sidenav-container.mat-sidenav-container {
  z-index: initial;
  background-color: transparent;
  color: inherit;
  height: 100%;
  min-height: 100vh;

  .mat-sidenav-content {
    display: flex;
    flex-direction: column;
    z-index: initial;
    height: inherit;
    min-height: inherit;
  }
}
