.rounded-counter {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);
  height: 26px;
  width: 26px;
  border-radius: 50%;
  overflow: hidden;
  color: var(--font-text-base);
  font-size: 14px;
}
