@mixin slider-value($message) {
  .mdc-slider__thumb-knob {
    &:after {
      content: $message;
    }
  }
}

.mdc-slider__thumb-knob {
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
    font-size: 12px;
  }
}

.slider .mat-mdc-slider {
  .mdc-slider__thumb-knob {
    &:after {
      content: '';
      top: 13px;
      white-space: break-spaces;
      text-align: center;
      line-height: 1;
    }
  }
}

.mdc-slider__track {
  background: $text-color;
  border-radius: 5px;
}

.mat-slider-min-value:not(.mat-slider-thumb-label-showing) .mat-slider-thumb {
  border-color: var(--secondary);
}

.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-track-fill, .mat-accent .mat-slid {
  background-color: var(--secondary) !important;
}

.slider {
  margin-left: -8px;

  &.hurt-1 {
    @include slider-value('Слабо');
  }

  &.en.hurt-1 {
    @include slider-value('Mildly');
  }

  &.ru.hurt-1 {
    @include slider-value('Слабо');
  }

  &.hurt-2 {
    @include slider-value('Помiрно');
  }

  &.en.hurt-2 {
    @include slider-value('Moderately');
  }

  &.ru.hurt-2 {
    @include slider-value('Умеренно');
  }

  &.hurt-3 {
    @include slider-value('Сильно');
  }

  &.en.hurt-3 {
    @include slider-value('Strongly');
  }

  &.ru.hurt-3 {
    @include slider-value('Сильно');
  }

  &.duration-1 {
    @include slider-value('1 день');
  }

  &.en.duration-1 {
    @include slider-value('1 day');
  }

  &.ru.duration-1 {
    @include slider-value('1 день');
  }

  &.duration-2 {
    @include slider-value('3 днi');
  }

  &.en.duration-2 {
    @include slider-value('3 days');
  }

  &.ru.duration-2 {
    @include slider-value('3 дня');
  }

  &.duration-3 {
    @include slider-value('Більше тижня');
  }

  &.en.duration-3 {
    @include slider-value('More than a week');
  }

  &.ru.duration-3 {
    @include slider-value('Больше недели');
  }
}
