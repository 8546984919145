.ng-select-custom {
  position: relative;

  .mat-mdc-form-field-error {
    transition: .3s;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}

.ng-select:not(.language) {
  caret-color: var(--font-text-base);

  .ng-select-container {
    min-height: 40px;
    padding-top: 1px;
    color: var(--font-text-base) !important;
    cursor: pointer !important;

    &:after {
      border-color: var(--font-text-base) !important;
    }
  }

  .ng-value-container,
  input {
    cursor: pointer !important;
    color: var(--font-text-base) !important;
  }

  .ng-placeholder {
    color: var(--font-text-base) !important;
  }

  .ng-arrow {
    color: var(--font-text-base) !important;
  }

  &.ng-touched.ng-invalid {
    .ng-select-container {
      &:after {
        border-color: $warn-color !important;
      }
    }

    .ng-placeholder {
      color: $warn-color !important;
    }
  }

  &.ng-select-focused,
  &.ng-dirty,
  .ng-has-value {
    .ng-placeholder {
      color: var(--font-text-base) !important;
    }
  }

  &.ng-select-focused {
    .ng-select-container {
      &:after {
        border-color: var(--font-text-base);
      }
    }
  }

  &.ng-select-disabled {
    .ng-placeholder {
      color: var(--gray) !important;
    }

    .ng-value {
      color: var(--gray) !important;
    }
  }

  &.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: var(--primary);
    color: var(--font-text-base);

    .ng-value-label {
      font-weight: 400;
    }

    .ng-value-icon {
      color: var(--font-text-base);
    }
  }
}

.ng-dropdown-panel[ng-reflect-append-to="body"] {
  margin-top: -15px;
}

.specializations.ng-select.ng-select-multiple .ng-select-container.ng-has-value .ng-value-container {
  margin-top: 1px;
}

.language {
  &.ng-select-single .ng-select-container .ng-arrow-wrapper {
    align-self: center !important;
    bottom: 2px !important;
  }

  .ng-select-container .ng-value-container {
    justify-content: center;
  }

  &.ng-select .ng-select-container:after {
    border-bottom: 0;
  }
}

