.mat-mdc-radio-button {
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-outer-circle{
    border-color: var(--gray) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-inner-circle {
    background-color: var(--secondary) !important;
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  &.mat-radio-checked {
    /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
    .mat-radio-outer-circle{
      border-color: var(--secondary) !important;
    }
  }
  /* TODO(mdc-migration): The following rule targets internal classes of radio that may no longer apply for the MDC version. */
  .mat-radio-ripple {
    display: none;
  }
}

.mat-mdc-radio-button .mdc-radio {
  padding: 5px !important;
}
