@import "breakpoints";

.medications {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  &_card {
    margin: 15px 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    border-radius: 12px;
    padding: 10px;
    flex-wrap: wrap;

    @include md {
      align-items: center;
      flex-direction: row;
      flex-wrap: nowrap;
    }

    &.treatment {
      background-color: var(--secondary-light);
    }

    &.procedure {
      background-color: var(--primary-extra-light);
    }
  }

  &_inner {
    display: flex;
    align-items: center;

    &.name {
      flex-grow: 1;
    }

    &.info {
      order: 1;
      width: 100%;
      justify-content: center;
      margin-top: 15px;

      @include md {
        margin-left: auto;
        margin-top: 0;
        order: 0;
        width: inherit;
      }
    }

    &.action {
      margin-left: auto;

      @include md {
        margin-left: 0;
      }
    }
  }

  &_icon {
    width: 40px;
    min-width: 40px;
    height: 40px;
    min-height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;

    .treatment & {
      background-color: var(--secondary-middle-light);
    }

    .procedure & {
      background-color: var(--primary);
    }
  }

  &_title {
    font-size: 16px;
    font-weight: 500;

    h3 {
      margin: 0 10px 10px 0;
      word-break: break-word;
    }
  }

  &_more {
    color: var(--secondary);
    text-decoration: underline;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  &_usertype {
    min-width: 16px;
    width: 16px;
  }

  &_check {
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 50%;
    margin-left: 10px;
    cursor: pointer;
    position: relative;

    &-fg {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid var(--gray);

      .done &,
      .checked & {
        background-color: var(--primary);
        border-color: var(--primary);
      }
    }

    &-bg {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      border-radius: 50%;
      background-color: var(--primary-extra-light);

      .done & {
        animation: .5s linear checked;
      }
    }

    .mat-icon {
      width: 20px;
      height: 20px;
      font-size: 20px;
      position: relative;
    }
  }

  &.no_points {
    align-items: center;
  }

  &_empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }

  &_desc {
    margin-top: 15px;
    color: var(--gray);
  }
}

.timepicker-dial {
  display: flex;
  justify-content: center;
  text-align: center;
}

@keyframes checked {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}

